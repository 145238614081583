export const OPEN_NOTIFICATION = 'OPEN_NOTIFICATION';
export const CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION';

//Records

export const GET_RECORD_BEGIN = 'GET_RECORD_BEGIN';
export const GET_RECORD_SUCCESS = 'GET_RECORD_SUCCESS';
export const GET_RECORD_FAILURE = 'GET_RECORD_FAILURE';
export const SET_RECORD_ID = 'SET_RECORD_ID';
export const SET_RECORD = 'SET_RECORD';

export const UPDATE_RECORD_BEGIN = 'UPDATE_RECORD_BEGIN';
export const UPDATE_RECORD_SUCCESS = 'UPDATE_RECORD_SUCCESS';
export const UPDATE_RECORD_FAILURE = 'UPDATE_RECORD_FAILURE';

export const DELETE_RECORD_BEGIN = 'DELETE_RECORD_BEGIN';
export const DELETE_RECORD_SUCCESS = 'DELETE_RECORD_SUCCESS';
export const DELETE_RECORD_FAILURE = 'DELETE_RECORD_FAILURE';

export const DOWNLOAD_CREDENTIAL_BEGIN = 'DOWNLOAD_CREDENTIAL_BEGIN';
export const DOWNLOAD_CREDENTIAL_SUCCESS = 'DOWNLOAD_CREDENTIAL_SUCCESS';
export const DOWNLOAD_CREDENTIAL_FAILURE = 'DOWNLOAD_CREDENTIAL_FAILURE';

export const SHARE_CREDENTIAL_BEGIN = 'SHARE_CREDENTIAL_BEGIN';
export const SHARE_CREDENTIAL_SUCCESS = 'SHARE_CREDENTIAL_SUCCESS';
export const SHARE_CREDENTIAL_FAILURE = 'SHARE_CREDENTIAL_FAILURE';

export const GET_CREDENTIAL_LINK_BEGIN = 'GET_CREDENTIAL_LINK_BEGIN';
export const GET_CREDENTIAL_LINK_SUCCESS = 'GET_CREDENTIAL_LINK_SUCCESS';
export const GET_CREDENTIAL_LINK_FAILURE = 'GET_CREDENTIAL_LINK_FAILURE';

export const REVOKE_CREDENTIAL_BEGIN = 'REVOKE_CREDENTIAL_BEGIN';
export const REVOKE_CREDENTIAL_SUCCESS = 'REVOKE_CREDENTIAL_SUCCESS';
export const REVOKE_CREDENTIAL_FAILURE = 'REVOKE_CREDENTIAL_FAILURE';

export const APPROVAL_ACTION_BEGIN = 'APPROVAL_ACTION_BEGIN';
export const APPROVAL_ACTION_SUCCESS = 'APPROVAL_ACTION_SUCCESS';
export const APPROVAL_ACTION_FAILURE = 'APPROVAL_ACTION_FAILURE';

// for now - used only in subject page's draft table
export const PREVIEW_DRAFT_RECORD_BEGIN = 'PREVIEW_DRAFT_RECORD_BEGIN';
export const PREVIEW_DRAFT_RECORD_SUCCESS = 'PREVIEW_DRAFT_RECORD_SUCCESS';
export const PREVIEW_DRAFT_RECORD_FAILURE = 'PREVIEW_DRAFT_RECORD_FAILURE';

export const LOGOUT_USER = 'LOGOUT_USER';
export const TOGGLE_LOGOUT_MODAL = 'SHOW_LOGOUT_MODAL';

//Analytics

export const GET_CREDENTIAL_INFO_BEGIN = 'GET_CREDENTIAL_INFO_BEGIN';
export const GET_CREDENTIAL_INFO_SUCCESS = 'GET_CREDENTIAL_INFO_SUCCESS';
export const GET_CREDENTIAL_INFO_FAILURE = 'GET_CREDENTIAL_INFO_FAILURE';

export const GET_CREDENTIAL_GRAPH_INFO_BEGIN =
  'GET_CREDENTIAL_GRAPH_INFO_BEGIN';
export const GET_CREDENTIAL_GRAPH_INFO_SUCCESS =
  'GET_CREDENTIAL_GRAPH_INFO_SUCCESS';
export const GET_CREDENTIAL_GRAPH_INFO_FAILURE =
  'GET_CREDENTIAL_GRAPH_INFO_FAILURE';

export const GET_CREDENTIAL_STATS_BEGIN = 'GET_CREDENTIAL_STATS_BEGIN';
export const GET_CREDENTIAL_STATS_SUCCESS = 'GET_CREDENTIAL_STATS_SUCCESS';
export const GET_CREDENTIAL_STATS_FAILURE = 'GET_CREDENTIAL_STATS_FAILURE';

export const GET_VERIFICATION_LOG_BEGIN = 'GET_VERIFICATION_LOG_BEGIN';
export const GET_VERIFICATION_LOG_SUCCESS = 'GET_VERIFICATION_LOG_SUCCESS';
export const GET_VERIFICATION_LOG_FAILURE = 'GET_VERIFICATION_LOG_FAILURE';

// Socket

export const SET_NEW_SOCKET = 'SET_NEW_SOCKET';
export const SET_SIDEBAR_OPEN = 'SET_SIDEBAR_OPEN';
