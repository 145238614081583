import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/styles/makeStyles";
import React from "react";
import { useRouteMatch } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  headingMain: {
    textAlign: "left",
  },
  title: {
    marginBottom: 2,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      display: "inline-block",
      paddingRight: 10,
    },
  },
  line: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "inline",
    },
  },
  about: {
    color: "#6b6b6b",
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 0,
      display: "inline-block",
      paddingLeft: 10,
    },
  },
}));

const Heading = () => {
  const classes = useStyles();
  let { path } = useRouteMatch();
  let title = "Issuers";
  let about = "Credential Issuer Profiles";

  if (path.match("/subjects")) {
    title = "Certificate";
    about = "";
  } else if (path.match("/activities")) {
    title = "Issuance Activities​";
    about = null;
  } else if (path.match("/settings")) {
    title = "Account Settings​";
    about = null;
  } else if (path.match("/holders")) {
    title = "Holders​";
    about = "Holder Profiles";
  }

  return (
    <div className={classes.headingMain}>
      <Typography variant="h3" className={classes.title} color="primary">
        {title}
      </Typography>
      {about ? <span className={classes.line}>|</span> : null}
      <Typography variant="body2" className={classes.about}>
        {about}
      </Typography>
    </div>
  );
};

export default React.memo(Heading);
