import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import PCMCTextLogo from "assets/images/pcmc-text-logo.png";
import CustomTooltip from "components/CustomTooltip";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useRouteMatch } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  breadCrumbList: {
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    fontSize: "10px",
    color: "#354052",
    "& .gray-link": {
      color: "#6b6b6b",
    },
  },
  headerRight: {
    position: "relative",
    top: "12px",
    [theme.breakpoints.down("sm")]: {
      top: 0,
      marginLeft: 10,
    },
  },
  appTitle: {
    [theme.breakpoints.down("sm")]: {
      "& h1": {
        fontSize: 20,
        marginBottom: 1,
      },
    },
  },
  link: {
    fontSize: 14,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      textOverflow: "ellipsis",
      maxWidth: 200,
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  },
  mainLogo: {
    margin: "0 auto 20px",
  },
}));

const PageTitle = () => {
  const [title, setTitle] = useState("");
  const [currentPath, setCurrentPath] = useState("");
  const classes = useStyles();
  const { path } = useRouteMatch();
  let { pathname } = useLocation();
  const issuerData = useSelector((state) => state.issuer.issuerDetails.data);
  const subjectData = useSelector((state) => state.getSubjectInfo.response);
  const batchDetails = useSelector((state) => state.logs.batchDetails.data);

  useEffect(() => {
    switch (path) {
      case "/credentials": {
        setTitle("Credentials");
        break;
      }
      case "/issuers": {
        setTitle("Issuer Profiles");
        break;
      }
      case "/subjects": {
        setTitle("Add TDR");
        break;
      }
      case "/holders": {
        setTitle("Development Rights Holder");
        break;
      }
      case "/holders/list": {
        setTitle("Holders");
        break;
      }
      case "/activities": {
        setTitle("Activities");
        break;
      }
      case "/settings": {
        setTitle("Settings");
        break;
      }
      case "/about": {
        setTitle("About");
        break;
      }
      case "/issuance-process": {
        setTitle("Issue Credentials");
        break;
      }
      default:
        break;
    }
  }, [path]);

  useEffect(() => {
    switch (path) {
      case "/credentials": {
        setCurrentPath(`Credentials`);
        if (pathname.includes("/analytics")) {
          if (issuerData?.name) {
            setCurrentPath(
              `Credentials / Credential Details / ${issuerData?.name} / Analytics`
            );
          }
        } else if (issuerData?.name) {
          setCurrentPath(
            `Credentials / Credential Details / ${issuerData?.name}`
          );
        }

        break;
      }

      case "/holders": {
        setCurrentPath(`Holders`);
        if (pathname.includes("/analytics")) {
          if (issuerData?.name) {
            setCurrentPath(
              `Holders / Holder Details / ${issuerData?.name} / Analytics`
            );
          }
        } else if (issuerData?.name) {
          setCurrentPath(`Holders / Holder Details / ${issuerData?.name}`);
        }

        break;
      }

      case "/holders/list": {
        setCurrentPath(`Holders / List`);

        break;
      }

      case "/issuers": {
        setCurrentPath(`Issuers`);
        if (pathname.match("/issuers/new-issuer")) {
          setCurrentPath(
            pathname
              .replace("/dashboard", " ")
              .replace("/", "")
              .replaceAll("/", " / ")
              .replaceAll("-", " ")
          );
        } else if (pathname.includes("/update")) {
          if (issuerData?.name) {
            setCurrentPath(
              `Issuers / Issuer Details / ${issuerData?.name} / Update`
            );
          }
        } else if (pathname.includes("/analytics")) {
          if (issuerData?.name) {
            setCurrentPath(
              `Issuers / Issuer Details / ${issuerData?.name} / Analytics`
            );
          }
        } else if (pathname.match("/issuers/issuer-details")) {
          if (issuerData?.name) {
            setCurrentPath(`Issuers / Issuer Details / ${issuerData?.name}`);
          }
        }
        break;
      }
      case "/subjects": {
        setCurrentPath(`Subjects`);
        if (pathname.match("/subjects/new-subject")) {
          setCurrentPath(
            pathname
              .replace("/dashboard", " ")
              .replace("/", "")
              .replaceAll("/", " / ")
              .replaceAll("-", " ")
          );
        } else if (pathname.includes("/update")) {
          if (subjectData?.title) {
            setCurrentPath(
              `Subjects / Subject Details / ${subjectData?.title} / Update`
            );
          }
        } else if (pathname.includes("/analytics")) {
          if (subjectData?.title) {
            setCurrentPath(
              `Subjects / Subject Details / ${subjectData?.title} / Analytics`
            );
          }
        } else if (pathname.includes("/subjects/subject-details")) {
          if (subjectData?.title) {
            setCurrentPath(
              `Subjects / Subject Details / ${subjectData?.title}`
            );
          }
        }
        break;
      }
      case "/activities": {
        setCurrentPath(`Activities`);
        if (pathname.match("/activities/activities-details")) {
          if (batchDetails?.name) {
            setCurrentPath(
              `Activities / Activities Details / ${batchDetails?.name}`
            );
          }
        }
        break;
      }
      case "/settings": {
        setCurrentPath(`Settings`);
        if (pathname.match("/settings/profile")) {
          setCurrentPath("Settings / Profile");
        } else if (pathname.match("/settings/security")) {
          setCurrentPath("Settings / Security");
        }
        break;
      }
      default:
        break;
    }
  }, [pathname, path, issuerData, subjectData, batchDetails]);

  let show = path === "/dashboard";
  let logo = show ? (
    <div className={`${classes.mainLogo}`}>
      <img alt="App Logo" src={PCMCTextLogo} width="200" height="65" />
    </div>
  ) : null;
  let customTitle = !show ? (
    <div className={classes.appTitle}>
      <Typography variant="h1">{title}</Typography>
    </div>
  ) : null;
  let appPath = !path.match("/issuance-process") ? (
    <div className={classes.breadCrumbList}>
      {show ? (
        <>
          {null}
          {/* <CustomTooltip title="Dashboard">
            <Typography
              variant="subtitle2"
              component="p"
              className={`${show ? " " : "gray-link"}`}
            >
              {show ? "Dashboard" : null}
            </Typography>
          </CustomTooltip> */}
        </>
      ) : (
        <CustomTooltip title={currentPath}>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography
              variant="subtitle2"
              component="p"
              className={classes.link}
            >
              {currentPath}
            </Typography>
          </Breadcrumbs>
        </CustomTooltip>
      )}
    </div>
  ) : null;

  return (
    <div className={classes.headerRight}>
      {logo}
      {customTitle}
      {appPath}
    </div>
  );
};
export default React.memo(PageTitle);
