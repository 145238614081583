import AppBar from '@material-ui/core/AppBar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import React, { useCallback, useEffect, useState } from 'react';
// import SearchIcon from "assets/images/svg/search-blue.svg";
import MenuIcon from '@material-ui/icons/Menu';
import DefaultProfilePhoto from 'assets/images/DefaultProfilePhoto.jpg';
import CustomTooltip from 'components/CustomTooltip';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PageTitle from './components/PageTitle';
import VerfiedDrawer from './components/VerifiedDrawer/VerifiedDrawer';
import { getUserDetails } from './header.action';
// import HeaderDetailSkeleton from "components/Skeletons/HeaderDetailSkeleton";
import HeaderSkeleton from 'components/Skeletons/HeaderSkeleton';
import { io } from 'socket.io-client';
import { setNewSocket, toggleSideBar } from 'store/commonActions';

const useStyles = makeStyles((theme) => ({
  siteHeader: {
    display: 'flex',
    width: '100%',
    background: '#ffffff',
    zIndex: 3,
  },
  headerContentWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xl')]: {
      padding: '20px 30px 20px 124px',
    },
    [theme.breakpoints.up('xl')]: {
      padding: '20px 30px 20px 136px',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '10px 0',
      padding: '5px 0px 5px 20px',
    },
  },
  pageTitle: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
  headerRight: {
    display: 'flex',
    alignItems: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      flexDirection: 'row-reverse',
    },
  },
  headerButton: {
    width: '44px',
    height: '44px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px',
    backgroundColor: '#083f85',
    borderRadius: '100%',
    marginRight: '15px',
    '&:hover': {
      backgroundColor: '#083f85',
    },
    '&.gray-button': {
      backgroundColor: '#ececec',
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: '0',
    },
  },
  userNameImageWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  userName: {
    textAlign: 'right',
    marginRight: '15px',
    maxWidth: 200,
    textOverflow: 'ellipsis',
    '& .normal-text': {
      fontWeight: 400,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  iconWrap: {
    lineHeight: 0,
    display: 'block',
    position: 'relative',
    '& img': {
      width: '16px',
      height: 'auto',
      paddingBottom: ' 3px',
      paddingLeft: '0.5px',
    },
  },
  profileImage: {
    width: '53px',
    border: '2px solid #083f85',
    cursor: 'pointer',
    height: '53px',
    display: 'flex',
    padding: '1px',
    overflow: 'hidden',
    alignItems: 'center',
    borderRadius: '50%',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: 42,
      height: 42,
    },
    '& img': {
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      objectFit: 'cover',
    },
  },
  menuBtn: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  menu: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  responsiveMenu: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  dot: {
    backgroundColor: '#f35162',
    width: 7,
    height: 7,
    borderRadius: '50%',
    position: 'absolute',
    bottom: 10,
    right: '-3px',
    border: '1px solid #fff',
  },
  uname: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: 200,
  },
}));

function Header({ display }) {
  // const [notificationEL, SetNotificationEL] = useState(null);
  const [openVerifiedDrawer, setOpenVerifiedDrawer] = useState(false);
  // const notificationRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const userDetails = useSelector((state) => state.getUser.data);
  const classes = useStyles();
  const { loading } = useSelector((state) => state.getUser);
  const { open: openDrawer } = useSelector(({ sideBar }) => sideBar);

  useEffect(() => {
    const domian = process.env.REACT_APP_SOCKET_HOST;
    const options = {
      path: '/socket.io',
      forceNew: true,
      reconnectionAttempts: 3,
      timeout: 2000,
    };
    const newSocket = io(domian, options);

    newSocket?.on('connect', () => {
      dispatch(setNewSocket(newSocket));
    });

    return () => newSocket?.close();
  }, [dispatch]);

  useEffect(() => {
    if (!userDetails.name) {
      dispatch(getUserDetails());
    }
  }, [dispatch, userDetails.name]);

  // const handleNotification = (event) => {
  //   SetNotificationEL(notificationRef.current);
  // };
  // const handleNotificationClose = () => {
  //   SetNotificationEL(null);
  // };
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setOpenVerifiedDrawer(open);
  };

  // const handleIssuanceProcess = () => {
  //   history.push("/issuance-process");
  // };

  const toggleSidebarDrawer = useCallback(() => {
    dispatch(toggleSideBar(!openDrawer));
  }, [dispatch, openDrawer]);

  const handleViewProfile = (event) => {
    history.push('/settings/profile');
  };

  if (!display) return null;
  return (
    <>
      <AppBar
        position='fixed'
        color='transparent'
        elevation={0}
        className={classes.siteHeader}
      >
        <Toolbar className={classes.headerContentWrapper}>
          <div className={classes.pageTitle}>
            <MenuIcon
              className={classes.menuBtn}
              onClick={toggleSidebarDrawer}
            />
            <PageTitle />
          </div>
          <div className={classes.headerRight}>
            {/* <div className={classes.responsiveMenu}>
              <HeaderMenu />
            </div> */}
            <div className={classes.menu}>
              {/* <CustomTooltip title="Recently Verified">
                <IconButton
                  className={classes.headerButton}
                  onClick={toggleDrawer(true)}
                >
                  <i className={classes.iconWrap}>
                    <VerifiedIcon />
                  </i>
                </IconButton>
              </CustomTooltip>
              <CustomTooltip title="Issuance Process">
                <IconButton
                  className={classes.headerButton}
                  onClick={handleIssuanceProcess}
                >
                  <i className={classes.iconWrap}>
                    <AddIcon />
                  </i>
                </IconButton>
              </CustomTooltip> */}
              {/* <CustomTooltip title='Search'>
                <IconButton
                  className={`${classes.headerButton} gray-button`}
                  style={{ display: "none" }}
                >
                  <i className={classes.iconWrap}>
                    <img src={SearchIcon} alt='search' />
                  </i>
                </IconButton>
              </CustomTooltip> */}
              {/* <CustomTooltip title="Notifications">
                <IconButton
                  className={`${classes.headerButton} gray-button`}
                  onClick={handleNotification}
                  ref={notificationRef}
                >
                  <i className={classes.iconWrap}>
                    <img src={BellIcon} alt="search" />
                    <Badge variant="dot" className={classes.dot}></Badge>
                  </i>
                </IconButton>
              </CustomTooltip> */}
            </div>
            {/* <NotificationMenu
              notificationEL={notificationEL}
              handleNotificationClose={handleNotificationClose}
            /> */}
            {!loading ? (
              <div className={classes.userNameImageWrap}>
                <div className={classes.userName}>
                  <Typography variant='subtitle2' className='normal-text'>
                    Hello, Administrator
                  </Typography>
                  <CustomTooltip title={userDetails?.name || ''}>
                    <Typography variant='h6' className={classes.uname}>
                      {userDetails?.name}!
                    </Typography>
                  </CustomTooltip>
                </div>
                <div
                  className={classes.profileImage}
                  onClick={handleViewProfile}
                  aria-hidden='true'
                >
                  <img
                    src={userDetails.profile_image || DefaultProfilePhoto}
                    alt='User'
                    width='45'
                    height='45'
                  />
                </div>
              </div>
            ) : (
              <HeaderSkeleton />
            )}
          </div>
        </Toolbar>
      </AppBar>
      <VerfiedDrawer open={openVerifiedDrawer} toggleDrawer={toggleDrawer} />
    </>
  );
}

export default React.memo(Header);
